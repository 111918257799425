import React from 'react';

const Works = () => {
  const worksList = [
    {
      title: 'Project Reset',
      client: 'Qantas',
      imageUrl: require('../../assets/images/works/thumbnail-ff.jpg'),
      url: 'https://frequentflyer.qantas.com',
    },
    {
      title: 'Car Insurance',
      client: 'Qantas',
      imageUrl: require('../../assets/images/works/thumbnail-car.jpg'),
      url: 'https://insurance.qantas.com/car-insurance',
    },
    {
      title: 'Salary Report',
      client: 'Seek',
      imageUrl: require('../../assets/images/works/thumbnail-seek.jpg'),
      url: 'https://www.seek.com.au/career-advice/page/salary-insights',
    },
    {
      title: 'BudgetPlanner',
      client: 'Personal Project',
      imageUrl: require('../../assets/images/works/thumbnail-budget.jpg'),
      url: 'https://budget-planner-app.herokuapp.com/',
    },
    {
      title: 'Chefs Recipe',
      client: 'Personal Project',
      imageUrl: require('../../assets/images/works/thumbnail-chefs.jpg'),
      url: 'https://chefs-recipe.netlify.com/',
    },
  ];

  const renderWorks = () => {
    return worksList.map((work, index) => (
      <a
        key={index}
        target="_blank"
        rel="noopener noreferrer"
        className="works-thumbnail"
        href={work.url}
      >
        <img src={work.imageUrl} alt={work.title} />
        <div className="thumbnail-tile">
          <h3>{work.title}</h3>
          <p>{work.client}</p>
        </div>
      </a>
    ));
  };

  return (
    <section className="works">
      <div className="container">
        <div className="works-container">
          <h2>Works</h2>
          <p>Here are some works (commercial and personal projects)</p>
          <div className="works-group">{renderWorks()}</div>
        </div>
      </div>
    </section>
  );
};

export default Works;
