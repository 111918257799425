import React from 'react';
import Hero from './Hero';
import Works from './Works';
import Brands from './Brands';
import About from './About';
import Contact from './Contact';
import Youtube from './Youtube/Youtube';

export const Index = () => {
  return (
    <div>
      <Hero />
      <Works />
      <Brands />
      <About />
      <Youtube />
      <Contact />
    </div>
  );
};

export default Index;
