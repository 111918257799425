import React from 'react';

const Brands = () => {
  const brandsList = [
    {
      name: 'qantas',
      url: require('../../assets/images/logos/logo-qantas.svg'),
    },
    {
      name: 'afterpay',
      url: require('../../assets/images/logos/logo-afterpay.svg'),
    },
    {
      name: 'seek',
      url: require('../../assets/images/logos/logo-seek.svg'),
    },
    {
      name: 'nine',
      url: require('../../assets/images/logos/logo-nine.svg'),
    },
    {
      name: 'suncorp',
      url: require('../../assets/images/logos/logo-suncorp.png'),
    },
  ];

  const renderLogos = () =>
    brandsList.map(({ url, name }, index) => (
      <div key={index}>
        <img src={url} alt={name} />
      </div>
    ));

  return (
    <section className="brands">
      <div className="container">
        <div className="brands-container">
          <h2>Brands</h2>
          <p>Some brands I've worked with</p>
          <div className="brands-group">{renderLogos()}</div>
        </div>
      </div>
    </section>
  );
};

export default Brands;
