import React, { useEffect, useRef } from 'react';

import lottie from 'lottie-web';
import { TimelineMax, Power1 } from 'gsap';

const Hero = () => {

  const container = useRef(null)
  const animationData = require('../../assets/lotties/phone.json')

  useEffect(() => {
    const tl = new TimelineMax();
    const ease = Power1.easeInOut;
    tl.from('.hero-title', 1, { y: 10, autoAlpha: 0, ease }, 0);
    tl.from('.hero-subtitle', 1, { y: 10, autoAlpha: 0, ease }, 1);
    tl.staggerFrom('.social-links a', 1, { autoAlpha: 0, y: 10, ease }, 0.3, 2);

    const observer = new IntersectionObserver(entry => {
      const first = entry[0]
      first.isIntersecting ? lottie.play() : lottie.pause()
    })

    observer.observe(container.current);

    lottie.loadAnimation({
      animationData,
      container: container.current,
      renderer: 'svg',
      autoplay: false,
      loop: true
    })

  }, [animationData]);


  return (
    <section className="hero">
      <div className="container">
        <div className="hero-container">
          <div className="lottie" ref={container}/>
          <h1 className="hero-title">ANDREW B</h1>
          <p className="hero-subtitle">Sydney Frontend Developer</p>
          <div className="social-links">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.linkedin.com/in/andrewbonifacio/"
            >
              LinkedIn
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.twitter.com/andrewbdesign"
            >
              Twitter
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.codepen.io/androobay"
            >
              Codepen
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.github.com/andrewbdesign"
            >
              Github
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.youtube.com/channel/UCvCjQWcRqbHzqc3BW8AbBHA"
            >
              Youtube
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
