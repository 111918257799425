import React, { Fragment } from 'react';
import Homepage from './homepage/index';
import Resume from './resume/index';
// import Twitter from './Twitter';
// import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

// import Nav from '../components/layout/Nav';
import Footer from '../components/layout/Footer';

const App = () => {
  return (
    <Fragment>
      {/* <Nav /> */}
      <Router>
        {/*<div className="twitter-section">
        <Twitter />
  </div> */}
        <Switch>
          <Route path="/" exact component={Homepage} />
          <Route path="/resume" exact component={Resume} />
        </Switch>
      </Router>
      <Footer />
    </Fragment>
  );
};

export default App;
