import React from 'react';

const Contact = () => {
  return (
    <section className="contact">
      <div className="container">
        <div className="contact-container">
          <h2>Say hello</h2>
          <p>
            If you think we should work together, feel free to send me a email
          </p>
          <a href="mailto:contact@andrewbdesign.com">
            contact@andrewbdesign.com
          </a>
        </div>
      </div>
    </section>
  );
};

export default Contact;
