import React, { Fragment } from 'react';

const Twitter = () => {
  return (
    <Fragment>
      <a
        className="twitter-timeline"
        href="https://twitter.com/andrewbdesign?ref_src=twsrc%5Etfw"
        data-width="400"
        data-height="400"
      >
        Tweets by andrewbdesign
      </a>
    </Fragment>
  );
};

export default Twitter;
