import React from 'react';

import './Youtube.scss';

const Youtube = () => {
  return (
    <section className="youtube">
      <div className="container">
        <div className="youtube-container">
          <h2>Learn</h2>
          <p>Check out my <a href="https://www.youtube.com/channel/UCvCjQWcRqbHzqc3BW8AbBHA" rel="noopener noreferrer" target="_blank">Youtube Channel</a></p>
        </div>
        <div className="video-container">
          <iframe
            title="youtube"
            src="https://www.youtube.com/embed/LIoRZZ_va_o"
            frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen></iframe>
        </div>
      </div>
    </section>
  );
};

export default Youtube;
