import React from 'react';

const About = () => {
  return (
    <section className="about">
      <div className="container">
        <div className="about-container">
          <img
            className="about-profile"
            src={require('../../assets/images/profile.jpeg')}
            alt="profile"
          />
          <div className="body-copy">
            <p>
              Hi, my name is Andrew. I have over 4.5 years of experience working
              in creative agencies as a Frontend Dev. I am based in Sydney,
              Australia and love working on interactive, beautiful, and
              meaningful websites.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
