import React from 'react';
import Twitter from '../Twitter';

const Footer = () => {
  return (
    <footer>
      <div className="container">
        <h2>Connect with me</h2>
        <p>Follow me <a href="https://twitter.com/andrewbdesign" rel="noopener noreferrer">@andrewbdesign</a></p>
        <div className="twitter-footer">
          <Twitter />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
